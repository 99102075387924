th:first-child {
  border-top-left-radius: 8px;
  // border-bottom-left-radius: 8px;
  padding-left: 24px;
}
td:first-child {
  padding-left: 24px;
}

th:last-child {
  border-top-right-radius: 8px;
  // border-bottom-right-radius: 8px;
  padding-right: 24px;
  text-align: right;
}
td:last-child {
  padding-right: 24px;
  text-align: right;
}

tr:hover {
  cursor: pointer;
}

th:last-child > div,
td:last-child > div {
  justify-content: flex-end;
}

th:first-child > div,
td:first-child > div {
  justify-content: flex-start;
}

td {
  text-overflow: ellipsis;
  overflow: hidden;
}
