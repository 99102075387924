$avatar_size: 50px;
$editor-background-color: rgba(239, 239, 240, 1);
$card-border: 1px solid rgba(0, 0, 0, 0.2);
$card-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);

.wmde-markdown img {
  max-height: 200px;
  object-fit: contain;
  border-style: none;
  box-sizing: content-box;
  background-color: var(--color-canvas-default);

  &:hover {
    cursor: pointer;
  }
}

.comment_node {
  &.avatar {
    width: $avatar_size;
    height: $avatar_size;
    border-radius: 50%;
    background-color: lightblue;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  &.profile_picture {
    width: $avatar_size;
    height: $avatar_size;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  &.root {
    margin-top: 1rem;
    border: $card-border;
    border-radius: 10px;
    box-shadow: $card-shadow;
  }

  &.input {
    color: black;
    background-color: $editor-background-color;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    box-shadow: $card-shadow;
    border: $card-border;
    margin-top: 0rem;

    .w-md-editor-toolbar {
      background-color: white;
      border: none;
      align-items: center;
      height: 40px;

      ul {
        height: 40px;
        display: flex;
        align-items: center;
      }

      button {
        width: 25px;
        height: auto;

        :hover {
          color: black;
        }

        svg {
          color: black;
        }
      }
    }
  }

  &.extra {
    display: flex;
    color: #e5e5ea;
    cursor: pointer;

    :hover {
      color: black;
    }
  }

  &.comment_time {
    font-size: 0.75rem;
    font-weight: 550;
    color: rgb(163, 163, 163);
    padding: 0;
    display: flex;

    p {
      margin-bottom: 0;
      margin-top: 0.25rem;
      display: flex;
    }
  }

  &.comment_user {
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    margin: 0;

    p {
      margin: 0;
      padding: 0;
    }
  }
}
