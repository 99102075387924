#atlas-map-state {
  display: none;
}

#atlas-map-style {
  display: none;
}

#atlas-map-shortcuts {
  display: none;
}

.mapboxgl-control-container {
  display: none;
}

.atlas-control-container {
  display: none;
}

.atlas-map-canvas {
  canvas {
    width: 100% !important;
  }
}

.map_popup {
  h1 {
    font-weight: bold;
    font-size: 1rem;
  }

  p {
    margin-bottom: 0;
    font-size: 0.9rem;
  }
  color: black;
  text-align: center;
  padding: 0.5rem;
}
