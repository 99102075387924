@import url('./assets/font/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('./assets/font/Inter.var.woff2');
}

img {
  height: inherit;
}

body {
  background-color: #f9fafb;
}

.ventory-border {
  border: 1px solid #f2f4f7;
  border-radius: 0.375rem;
}

.ventory-container {
  width: calc(100vw - 270px);
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.BeaconFabButtonFrame {
  visibility: hidden !important;
}

.BeaconContainer {
  right: 40px !important;
}

#helpscout_beacon {
  height: 60px;
  width: 100px;
  bottom: 200px;
  right: -50px;
  position: fixed;
  text-align: center;
  padding-top: 6px;
  transform: rotate(-90deg);
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
