$headerHeight: 40px;

.ventory-header {
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  height: $headerHeight;
  background-color: white;
  border-bottom: 1px solid #f2f4f7;
}
